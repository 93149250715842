<template>
<div class="content-panel">
  <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
    <el-col :span="20">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">{{msg('首页')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{msg('系统设置')}}</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>
    <el-col :span="4" style="text-align:right;">
      <el-button type="success" size="small" icon="el-icon-refresh" @click="refresh(true)" circle></el-button>
    </el-col>
  </el-row>
  
  <el-row :gutter="10">
    <el-col :xs="24" :sm="8" :lg="6" :xl="4" >
      <div class="row-wrap" :style="{height: isMinScreen? 'auto':docHeight+'px'}" style="margin-left:-5px;">
        <el-table :data="settings" style="width: 100%" size="mini" ref="rootTable"  stripe @row-click="selectRow">
          <el-table-column prop="label" :label="msg('设置')" min-width="150"></el-table-column>
          <el-table-column min-width="100">
            <template slot="header"> 
                <el-button type="primary" icon="el-icon-circle-plus-outline" size="mini" @click="add(root)">{{msg('添加')}}</el-button>
            </template>
            <template slot-scope="scope">
              <el-button type="primary" icon="el-icon-edit" size="mini" circle @click.stop="editRow(scope.row, true)"></el-button>
              <el-button type="danger" icon="el-icon-delete" size="mini"  circle @click.stop="deleteRow(scope.row)"></el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-col>
    <el-col :xs="24" :sm="16" :lg="18" :xl="20" >
      <div>
        <div class="row-wrap" style="display:flex; justify-content: space-between; margin-right:-5px;" >
          <div class="el-page-header__content">{{curr ? curr.label: ""}}</div>
          <el-button type="primary" size="mini" @click="add(curr)">
            <i class="el-icon-circle-plus-outline" style="display:inline-block;margin-right:4px;"></i>
            <span>添加</span>
          </el-button>
        </div>
        <div class="row-wrap" :style="{height: isMinScreen? 'auto':(docHeight-52)+'px'}" style="margin-right:-5px;" >
          <el-table :data="childrens" style="width: 100%" size="mini" ref="childTable"  stripe>
            <el-table-column prop="id" :label="msg('ID')" min-width="70"></el-table-column>
            <el-table-column prop="label" :label="msg('名称')" min-width="90"></el-table-column>
            <el-table-column prop="key" :label="msg('KEY')" min-width="100" ></el-table-column>
            <el-table-column prop="text" :label="msg('内容')" min-width="100" >
              <template slot-scope="scope">
                <span v-if="scope.row.type!=2">{{scope.row.text}}</span>
                <el-image v-else style="width: 80px; height: 80px;"
                  :src="imgBaseUrl+scope.row.text" 
                  :preview-src-list="[imgBaseUrl+scope.row.text]">
                </el-image>
              </template>
            </el-table-column>
            <el-table-column prop="icon" :label="msg('说明')" min-width="150" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="" :label="msg('操作')" min-width="100" :fixed="isMinScreen?'right':false">
              <template slot-scope="scope">
                <el-button type="primary" icon="el-icon-edit" size="mini" circle @click.stop="editRow(scope.row)"></el-button>
                <el-button type="danger" icon="el-icon-delete" size="mini"  circle @click.stop="deleteRow(scope.row)"></el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-col>
  </el-row>

  <el-dialog :title="dgTitle" :visible.sync="dgVisible" :fullscreen="isMinScreen" width="650px">
    <setting-edit :settings="origins" :setting="editItem" :parent="parent"
      v-on:closeDg="closeEditDialog" ref="settingEdit"/>
  </el-dialog>
</div>
</template> 

<script>
// @ is an alias to /src
import SettingEdit from "./SettingEdit.vue";
 
export default {
  name: 'role',
  components: {
    SettingEdit:SettingEdit
  },
  mounted:function(){
    this.refresh(true);
  },
  data(){
    return{
        settings: [],
        origins: [],
        childrens:[],
        root: {id: 0, label: this.msg("根设置")},
        curr: null,
        parent: null,
        dgTitle: null,
        dgVisible: false,
        imgBaseUrl: this.$kit.server+"/api/p/img?id=",
        editItem: null
    }
  }, 
  computed: {
    isMinScreen: {  //是否小屏幕，浏览器
        get() {
          return this.$store.state.isMinScreen;
        }
    },
    docHeight: {  //表格的高度
        get() {
          var height =  this.$store.state.docHeight;
          return height - 160;
        }
    }
  },
  methods: {
    refresh: function(refresh){  //刷新列表
      this.getSettings(refresh);
    },
    getSettings(refresh){
      var _this = this;
      this.$axios.get(this.$kit.api.settingRoot)
            .then(function(result){
                if(result.status){
                  var data = result.data;

                  if(_this.settings.length>0)
                    _this.settings.splice(0, _this.settings.length);
                  if(_this.origins.length>0){
                    _this.origins.splice(0, _this.origins.length);
                  }

                  _this.origins.push(_this.root);
                  
                  data.forEach( a => {
                    _this.origins.push(a);
                    _this.settings.push(a);
                  });

                  if(!_this.curr && _this.settings.length>0) _this.curr = _this.settings[0];
       
                  if(refresh){
                    _this.getChildren();
                  }

                }else{
                    _this.$message(result.msg || _this.msg('获取失败'));
                }
               
            });
    },
    getChildren(){
      var _this = this;

      if(!this.curr) return;

      this.$axios.post(this.$kit.api.settingList, {start: 0, length: 9999, parentId: this.curr.id})
            .then(function(result){
                if(result.status){
                  var data = result.data.data;

                  if(_this.childrens.length>0)
                    _this.childrens.splice(0, _this.childrens.length);

                  data.forEach( a => {
                    _this.childrens.push(a);
                  });

                }else{
                    _this.$message(result.msg || _this.msg('获取失败'));
                }
               
            });
    },
    add(p){
      if(parent){
        this.parent = p;
      }else{
        this.parent = this.root;
      }
      this.editItem = {
        id:null,
        label:null,
        text:null,
        key:null,
        parentId: this.parent.id,
        remark:null,
        type:0,
        deleted: this.msg("否")
      };
      this.dgTitle=this.msg("添加设置");
      this.dgVisible = true;
    },
    editRow(row, type){
      if(type)
        this.parent = this.root;
      else{
        this.parent = this.curr;
      }
      // console.log("clicked edit", row);
      this.editItem = row;
      
      this.dgTitle = this.msg('编辑设置')+" - "+this.curr.label;
      this.dgVisible = true;
    },
    deleteRow(row){
      //this.currMenu = row;
      //console.log("clicked delete", row);
      var _this = this;
      this.$confirm(this.fmt("确定要删除设置 %s 吗?", [row.label]), this.msg('提示'), {
        confirmButtonText: this.msg('确定'),
        cancelButtonText: this.msg('取消'),
        type: 'warning'
      }).then(() => {
        _this.$axios.post(_this.$kit.api.settingDelete, [row.id])
          .then(function(result){
            _this.$message(result.msg || _this.msg('删除成功'));
            _this.refresh(true);
        });
      });

    },
    selectRow(setting, column, event){
      console.log(12121);
      this.curr = setting;
      this.getChildren(); 
    },
    closeEditDialog(type){  //编辑弹窗关闭后的回调事件
      if(type){
        this.refresh(true);
      }
      this.dgVisible = false;
    },
  }
}
</script>

<style class="scss" scoped>
.custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
  }

</style>
