<template>
<el-form ref="form" :model="setting" label-width="80px">
    <el-row>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('设置名')">
                <el-input v-model="setting.label" style="max-width:250px;"></el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('KEY')">
                <el-input v-model="setting.key" style="max-width:250px;"></el-input>
            </el-form-item>
        </el-col>
    </el-row>
     <el-row>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('上级')">
                <el-input v-model="parent.label" v-popover:settingsPopover style="max-width:250px;"></el-input>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('可否删除')">
               <el-radio-group v-model="setting.deleted">
                    <el-radio :label="msg('是')"></el-radio>
                    <el-radio :label="msg('否')"></el-radio>
                </el-radio-group>
            </el-form-item>
        </el-col>
    </el-row>
    <el-row v-show="setting.parentId">
        <el-col :xs="24" :sm="12" >
            <el-form-item :label="msg('类型')">
                <el-select v-model="setting.type" :placeholder="msg('请选择')">
                    <el-option v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
             <el-form-item :label="msg('内容')" >
                <el-input v-show="setting.type == 0" v-model="setting.text" style="max-width:250px;"></el-input>
                <el-input-number  v-show="setting.type == 1" :precision="2" v-model="setting.text" :min="-999999" :max="999999" ></el-input-number>
                <div style="display:flex;" v-show="setting.type == 2">
                    <el-upload class="avatar-uploader" :action="uploadUrl"
                            :show-file-list="false"
                            :with-credentials="true"
                            :on-success="handleAvatarSuccess"
                            :before-upload="beforeAvatarUpload">
                        <img v-if="imageUrl" :src="imageUrl" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </div>
                <el-date-picker v-show="setting.type == 3" v-model="setting.text" type="date" :placeholder="msg('选择日期')"></el-date-picker>
            </el-form-item>
        </el-col>
    </el-row>
    <el-form-item :label="msg('说明')"  v-show="setting.parentId">
        <el-input
            type="textarea"
            :rows="3" style="max-width:250px;"
            :placeholder="msg('请输入内容')"
            v-model="setting.remark">
        </el-input>
    </el-form-item>
    

    <div style="text-align:center;">
        <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
        <el-button  size="mini" v-on:click="$emit('closeDg')">{{msg('取消')}}</el-button>
    </div>

    <el-popover
        ref="settingsPopover"
        placement="bottom"
        width="300"
        trigger="focus"
        v-model="settingsPopoverVisiable"
        >
            <el-table :data="settings" style="width: 100%" size="mini" ref="childTable" stripe @row-click="selectRow">
                <el-table-column prop="id" :label="msg('ID')" min-width="70"></el-table-column>
                <el-table-column prop="label" :label="msg('名称')" min-width="90"></el-table-column>
            </el-table>
    </el-popover>
</el-form> 
</template>

<script>
export default {
  name: 'SettingEdit',
  data(){
    return{
        uploadUrl: this.$kit.api.upload+"?type=setting&press=0",
        settingsPopoverVisiable:false,
        options:[{label: this.msg('文本'),value: 0},{label: this.msg('数字'),value: 1},{label: this.msg("图片"),value: 2},{label: this.msg("日期"),value: 3}]
    }
  }, 
  computed:{
      imageUrl:{
          get(){
              if(this.setting.text){
                  return this.$kit.api.imgView+this.setting.text;
              }
              return null;
          }
      }
  },
  props:{
      settings:Array,
      parent: Object,
      setting: Object,
  },
  mounted: function(){
       
  },
  methods:{
    onSubmit(){
        if(!this.setting.key){
            this.$message("不能为空，已存在的key不能随意修改");
            return;
        }
        if(!this.setting.parentId){
            if(this.setting.parentId!=0){
                this.$message("父集节点不能为空！");
                return;
            }
        }

        var url = null;
        if(this.setting.id==null){
            url = this.$kit.api.settingCreate;
        }else{
            url = this.$kit.api.settingUpdate;
        }

        var _this = this;
        this.$axios.post(url, this.setting)
            .then(function(result){
                if(result.status){
                    _this.$alert(result.msg || (_this.id==null? _this.msg('创建成功'):_this.msg('更新成功')), _this.msg('提示'), {
                        confirmButtonText: _this.msg('确定'),
                        callback: action => {
                            if(result.status){
                                _this.$emit("closeDg", true);
                            }
                        }
                    });
                }else{
                    _this.$message(result.msg || (_this.id==null? _this.msg('创建失败'):_this.msg('更新失败')));
                }
               
            });
    },
    selectRow(setting, column, event){
      this.parent.label = setting.label;
      this.setting.parentId = setting.id;
      this.settingsPopoverVisiable = false;
    },
    handleAvatarSuccess(res, file,fileList) {
        //console.log(res , "11", file);
        if(res.status){
            this.setting.text = res.data;
        }else{
            this.$message(res.msg || this.msg("上传失败"));
        }
    },
    beforeAvatarUpload(){
        // let isJPG = file.type === 'image/jpeg';
        // let isLt2M = file.size / 1024 / 1024 < 2;

        // if (!isJPG) {
        //   this.$message.error('上传头像图片只能是 JPG 格式!');
        // }
        // if (!isLt2M) {
        //   this.$message.error('上传头像图片大小不能超过 2MB!');
        // }
        // return isJPG && isLt2M;
        return true;
    }
  }
}
</script>

<style class="scss">
 .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }
  .avatar {
    width: 100px;
    height: 100px;
    display: block;
  }


</style>
